import { useState, useEffect } from "react";
import { ChargeType, ChargeRule, Charge, ChargeRulesType } from "../types";
import { getCharges } from "../utils";

const useCalculations = (initialCharges?: Charge) => {
  const [name, setName] = useState<string>("");
  const [charges, setCharges] = useState<ChargeRulesType>({
    fixed: [],
    variable: []
  });

  const defaultCharges = {
    fixed: [{ name: "", factor: "", of: [] }],
    variable: [{ name: "", factor: [], of: [] }]
  };

  useEffect(() => {
    if (initialCharges?.config?.length) {
      setName(initialCharges.name);
      setCharges(getCharges(initialCharges.config));
    } else resetCharges();
  }, []);

  const resetCharges = (type: "all" | ChargeType = "all") => {
    if (type === "all") {
      setCharges({ fixed: [], variable: [] });
    } else {
      setCharges(prevState => ({ ...prevState, [type]: [] }));
    }
  };

  const handleAddCharge = (type: ChargeType) => {
    setCharges(prevState => ({
      ...prevState,
      [type]: [...prevState[type], ...defaultCharges[type]]
    }));
  };

  const handleUpdateCharge = (
    index: number,
    field: keyof ChargeRule,
    value: string | string[],
    type: ChargeType
  ) => {
    setCharges(prevState => ({
      ...prevState,
      [type]: prevState[type].map((calc, i) =>
        i === index ? { ...calc, [field]: value } : calc
      )
    }));
  };

  const handleDeleteCharge = (index: number, type: ChargeType) => {
    setCharges(prevState => ({
      ...prevState,
      [type]: prevState[type].filter((_, i) => i !== index)
    }));
  };

  return {
    name,
    setName,
    charges,
    resetCharges,
    handleAddCharge,
    handleUpdateCharge,
    handleDeleteCharge
  };
};

export default useCalculations;
