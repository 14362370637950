import React from "react";
import {
  RotaInputNew,
  RotaButtonNew,
  iconNew as RotaIcon
} from "@teamrota/rota-design";
import { Variant } from "@teamrota/rota-design/dist/components/button";
import { ChargeVariationProps } from "../types";
import { getChargeName, isErrors } from "../utils";
import { InputRow, StyledCheckbox } from "../styles";

const { Bin } = RotaIcon;

const ChargeVariations = ({
  rule,
  index,
  factors,
  errors,
  onUpdateFactors
}: ChargeVariationProps) => {
  const toggleVariation = () => {
    onUpdateFactors(factors.length > 0 ? [] : [""]);
  };

  const onFactorChange = (index: number, value: string) => {
    const updatedFactors = [...factors];
    updatedFactors[index] = value;
    onUpdateFactors(updatedFactors);
  };

  const onRemoveFactor = (index: number) => {
    const updatedFactors = factors.filter((_, i) => i !== index);
    onUpdateFactors(updatedFactors);
  };

  return (
    <>
      <StyledCheckbox
        label="Add charge variation"
        isChecked={factors.length > 0}
        isDisabled={!rule.name || !rule.of?.length}
        onClick={toggleVariation}
      />
      {factors.length > 0 && (
        <>
          {factors.map((factor, factorIndex) => (
            <InputRow key={factorIndex} size={3}>
              <RotaInputNew
                label="Charge name"
                placeholder="Select a charge name"
                value={getChargeName(rule.name)}
                disabled={!rule.name}
                readOnly
              />
              <RotaInputNew
                label="Pay scale level"
                value={`Level ${factorIndex + 1}`}
                readOnly
              />
              <RotaInputNew
                label="Value"
                placeholder="Enter a number"
                helperText="Please enter the numerical charge to 2 decimal places"
                value={factor}
                onChange={e => onFactorChange(factorIndex, e.target.value)}
                isError={!!errors[`variable-factor-${index}-${factorIndex}`]}
                errorMessage={errors[`variable-factor-${index}-${factorIndex}`]}
              />
              <Bin onClick={() => onRemoveFactor(factorIndex)} />
            </InputRow>
          ))}
          <RotaButtonNew
            variant={Variant.outlined}
            isDisabled={
              factors.some(f => f === "") || isErrors(errors, "variable-factor")
            }
            onClick={() => onUpdateFactors([...factors, ""])}
          >
            Add another
          </RotaButtonNew>
        </>
      )}
    </>
  );
};

export default ChargeVariations;
