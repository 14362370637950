import React from "react";
import { RotaCalendar } from "@teamrota/rota-design";
import { RateCardProps } from "../types";

const Summary = ({ formState, updateForm }: RateCardProps) => {
  return (
    <>
      <p>Effective Date</p>
      <RotaCalendar
        selectedDate={formState.selectedDate}
        onChange={({ date }) => updateForm("selectedDate", date)}
        minDate={new Date()}
      />
    </>
  );
};

export default Summary;
