import React, { useState, useMemo } from "react";
import {
  ModalHeader,
  RotaNewModal,
  RotaButtonIcon,
  RotaInputNew,
  iconNew as RotaIcon,
  useToast,
  RotaDivider
} from "@teamrota/rota-design";
import ScaleRulesForm from "./components/ScaleRulesForm";
import CancelModal from "../CancelModal";
import SaveButtons from "../SaveButtons";
import useScales from "./hooks/use-scales";
import { Props, ScalePeriod, ScaleRule, StringMap } from "./types";
import {
  convertScaleRulesToConfig,
  isNameTaken,
  isScaleRulesFilled,
  isScaleRulesValid,
  isScaleRulesModified,
  validateFields
} from "./utils";
import { InputContainer } from "./styles";
import ScalePeriodForm from "./components/ScalePeriodForm";

const { Cancel } = RotaIcon;

const CreateEditScalesModal: React.FC<React.PropsWithChildren<Props>> = ({
  action,
  allSavedScales,
  initialScales,
  onClose,
  onSave
}) => {
  const {
    name,
    setName,
    scales,
    period,
    setPeriod,
    resetScales,
    handleAddScale,
    handleUpdateScale,
    handleDeleteScale
  } = useScales(initialScales);

  const [nameError, setNameError] = useState<string>("");
  const [ruleErrors, setRuleErrors] = useState<StringMap>({});
  const [periodErrors, setPeriodErrors] = useState<StringMap>({});
  const [isCancelModalOpen, setIsCancelModalOpen] = useState<boolean>(false);

  const { showToast } = useToast();

  const isModified = useMemo(
    () => isScaleRulesModified(name, period, scales, initialScales),
    [name, scales, period, initialScales]
  );

  const { isValid, isScalesValid } = useMemo(() => {
    const errors = { rules: ruleErrors, period: periodErrors };

    return isScaleRulesValid(name, period, scales, errors);
  }, [name, scales, period, ruleErrors, periodErrors]);

  const handleInputChange = async (
    field: keyof ScaleRule | keyof ScalePeriod,
    value: string,
    index?: number
  ) => {
    if (index !== undefined) {
      handleUpdateScale(index, field as keyof ScaleRule, value);
      setRuleErrors(prevErrors => ({
        ...prevErrors,
        [`${field}-${index}`]: validateFields(field, value)
      }));
    } else {
      setPeriod(prevState => ({ ...prevState, [field]: value }));
      setPeriodErrors(prevErrors => ({
        ...prevErrors,
        [field]: validateFields(field, value)
      }));
    }
  };

  const handleSave = async (isSaveAndAdd: boolean) => {
    if (isNameTaken(name, initialScales, allSavedScales)) {
      setNameError("Name already taken. Choose another.");
      showToast("Correct all errors before saving.", { severity: "error" });
      return;
    }

    const config = convertScaleRulesToConfig(period, scales);

    try {
      await onSave({ name, config });
      showToast("Record saved.", { severity: "success" });
      if (isSaveAndAdd) {
        setName("");
        resetScales();
      } else onClose();
    } catch (e) {
      showToast(`Error: ${(e as Error).message}`, { severity: "error" });
    }
  };

  const handleCloseModal = () => {
    if (
      (action === "Create" && isScaleRulesFilled(name, period, scales)) ||
      (action === "Edit" && isModified)
    ) {
      setIsCancelModalOpen(true);
    } else {
      onClose();
    }
  };

  return (
    <RotaNewModal
      size="medium"
      onClose={onClose}
      header={
        <ModalHeader
          title={`${action} Pay Scale`}
          endAction={
            <RotaButtonIcon onClick={handleCloseModal}>
              <Cancel />
            </RotaButtonIcon>
          }
        />
      }
    >
      <InputContainer>
        <RotaInputNew
          label="Name"
          placeholder="Enter a name"
          helperText="Please provide a name"
          value={name}
          onChange={({ target: { value } }) => {
            setName(value);
            setNameError("");
          }}
          isError={!!nameError}
          errorMessage={nameError}
        />
        <ScalePeriodForm
          period={period}
          errors={periodErrors}
          onInputChange={handleInputChange}
        />
        <RotaDivider />
        <ScaleRulesForm
          scales={scales}
          period={period}
          errors={ruleErrors}
          isAddMore={isScalesValid}
          onInputChange={handleInputChange}
          onAddScale={handleAddScale}
          onDeleteScale={handleDeleteScale}
        />
      </InputContainer>

      <SaveButtons
        action={action}
        isEnabled={isValid && isModified}
        onSave={handleSave}
      />

      {isCancelModalOpen && (
        <CancelModal
          onCancel={() => setIsCancelModalOpen(false)}
          onClose={onClose}
        />
      )}
    </RotaNewModal>
  );
};

export default CreateEditScalesModal;
