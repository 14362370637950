import { useState, useEffect } from "react";
import { RateRule, Rate, RateType, RateRulesType } from "../types";
import { getRates } from "../utils";

const useRates = (initialRates?: Rate) => {
  const [name, setName] = useState<string>("");
  const [rates, setRates] = useState<RateRulesType>({
    fixed: [],
    multiplier: []
  });

  const defaultRates = {
    fixed: [{ name: "", amount: "" }],
    multiplier: [{ name: "", factor: "" }]
  };

  useEffect(() => {
    if (initialRates?.config?.length) {
      setName(initialRates.name);
      setRates(getRates(initialRates.config));
    } else resetRates();
  }, []);

  const resetRates = (type: "all" | RateType = "all") => {
    if (type === "all") {
      setRates({ fixed: [], multiplier: [] });
    } else {
      setRates(prevState => ({ ...prevState, [type]: [] }));
    }
  };

  const handleAddRate = (type: RateType) => {
    setRates(prevState => ({
      ...prevState,
      [type]: [...prevState[type], ...defaultRates[type]]
    }));
  };

  const handleUpdateRate = (
    index: number,
    field: keyof RateRule,
    value: string,
    type: RateType
  ) => {
    setRates(prevState => ({
      ...prevState,
      [type]: prevState[type].map((rate, i) =>
        i === index ? { ...rate, [field]: value } : rate
      )
    }));
  };

  const handleDeleteRate = (index: number, type: RateType) => {
    setRates(prevState => ({
      ...prevState,
      [type]: prevState[type].filter((_, i) => i !== index)
    }));
  };

  return {
    name,
    setName,
    rates,
    resetRates,
    handleAddRate,
    handleUpdateRate,
    handleDeleteRate
  };
};

export default useRates;
