import React, { useState } from "react";
import { RotaStepper, RotaButtonNew } from "@teamrota/rota-design";
import { Variant } from "@teamrota/rota-design/dist/components/button";
import { InputContainer, StepperContainer, ButtonContainer } from "./styles";

export enum StepState {
  ON_PAGE = "onPage",
  OFF_PAGE = "offPage",
  DONE = "done",
  ERROR = "error"
}

interface StepperProps {
  steps: {
    label: string;
    isValid: boolean;
  }[];
  onSave: () => void;
  isSaving: boolean;
  children: (activeStepIndex: number) => React.ReactNode;
}

const Stepper = ({ steps, onSave, isSaving, children }: StepperProps) => {
  const [activeStepIndex, setActiveStepIndex] = useState(0);

  const goToStep = (index: number) => setActiveStepIndex(index);

  const getStepState = (index: number, isValid: boolean) => {
    if (index === activeStepIndex) {
      return isValid ? StepState.DONE : StepState.ON_PAGE;
    } else if (index < activeStepIndex) return StepState.DONE;
    else return StepState.OFF_PAGE;
  };

  const isFirstStep = activeStepIndex === 0;
  const isLastStep = activeStepIndex === steps.length - 1;

  return (
    <>
      <StepperContainer>
        <RotaStepper
          steps={steps.map((step, index) => ({
            label: step.label,
            state: getStepState(index, step.isValid)
          }))}
        />
      </StepperContainer>

      <InputContainer>{children(activeStepIndex)}</InputContainer>

      <ButtonContainer>
        {!isFirstStep && (
          <RotaButtonNew
            variant={Variant.outlined}
            onClick={() => goToStep(activeStepIndex - 1)}
          >
            Back
          </RotaButtonNew>
        )}
        <RotaButtonNew
          style={{ marginLeft: "auto" }}
          onClick={() =>
            isLastStep ? onSave() : goToStep(activeStepIndex + 1)
          }
          isDisabled={!steps[activeStepIndex].isValid}
          isLoading={isSaving}
        >
          {isLastStep ? "Save" : "Next"}
        </RotaButtonNew>
      </ButtonContainer>
    </>
  );
};

export default Stepper;
