import React, { useState, useEffect } from "react";
import {
  DropdownSelectNew,
  DropdownMultiSelectNew,
  iconNew as RotaIcon
} from "@teamrota/rota-design";
import ChargeVariations from "./ChargeVarIations";
import { BooleanMap, ChargeRulesFormProps } from "../types";
import { InputRow, StyledCheckbox } from "../styles";
import { chargeNameOptions } from "../utils";

const { Bin } = RotaIcon;

const VariableChargeRulesForm = ({
  charges,
  resetCharges,
  errors,
  onInputChange,
  onAddCharge,
  onDeleteCharge
}: ChargeRulesFormProps) => {
  const [isShowForm, setIsShowForm] = useState<boolean>(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState<BooleanMap>({});

  const type = "variable";

  useEffect(() => {
    setIsShowForm(charges.length > 0);
  }, [charges]);

  const onDropdownToggle = (key: string) => {
    setIsDropdownOpen(prevState => ({
      ...prevState,
      [key]: !prevState[key]
    }));
  };

  return (
    <div>
      <StyledCheckbox
        label="Add variable charge"
        isChecked={isShowForm}
        onClick={() => {
          const checked = !isShowForm;
          setIsShowForm(checked);
          if (!checked) resetCharges(type);
          else onAddCharge(type);
        }}
      />
      {isShowForm &&
        charges.map((rule, index) => (
          <>
            <InputRow key={index} size={2}>
              <DropdownSelectNew
                labelText="Charge name"
                placeholderText="Select a charge name"
                options={chargeNameOptions.filter((_, i) => i !== 0)}
                selectedValue={rule.name}
                isOpen={isDropdownOpen[`${type}-name-${index}`]}
                setIsOpen={() => onDropdownToggle(`${type}-name-${index}`)}
                onSelectOption={value =>
                  onInputChange(index, "name", value, type)
                }
              />
              <DropdownMultiSelectNew
                labelText="Charge from"
                placeholderText="Select where to charge from"
                options={chargeNameOptions}
                selectedValue={rule.of}
                isOpen={isDropdownOpen[`${type}-of-${index}`]}
                setIsOpen={() => onDropdownToggle(`${type}-of-${index}`)}
                onSelectOption={value =>
                  onInputChange(index, "of", value, type)
                }
              />
              <Bin onClick={() => onDeleteCharge(index, type)} />
            </InputRow>
            <ChargeVariations
              rule={rule}
              index={index}
              factors={rule.factor as string[]}
              errors={errors}
              onUpdateFactors={updatedFactors =>
                onInputChange(index, "factor", updatedFactors, type)
              }
            />
          </>
        ))}
    </div>
  );
};

export default VariableChargeRulesForm;
