import React, { useState, useMemo } from "react";
import {
  ModalHeader,
  RotaNewModal,
  RotaButtonIcon,
  RotaInputNew,
  iconNew as RotaIcon,
  useToast
} from "@teamrota/rota-design";
import RateRulesForm from "./components/RateRulesForm";
import CancelModal from "../CancelModal";
import SaveButtons from "../SaveButtons";
import useRates from "./hooks/use-rates";
import { Props, RateRule, RateType, StringMap } from "./types";
import {
  formatRates,
  isNameTaken,
  isRateRulesFilled,
  isRateRulesValid,
  isRateRulesModified,
  validateFields
} from "./utils";
import { InputContainer } from "./styles";

const { Cancel } = RotaIcon;

const CreateEditRatesModal: React.FC<React.PropsWithChildren<Props>> = ({
  action,
  allSavedRates,
  initialRates,
  onClose,
  onSave
}) => {
  const {
    name,
    setName,
    rates,
    resetRates,
    handleAddRate,
    handleUpdateRate,
    handleDeleteRate
  } = useRates(initialRates);

  const [nameError, setNameError] = useState<string>("");
  const [ruleErrors, setRuleErrors] = useState<StringMap>({});
  const [isCancelModalOpen, setIsCancelModalOpen] = useState<boolean>(false);

  const { showToast } = useToast();

  const isModified = useMemo(
    () => isRateRulesModified(name, rates, initialRates),
    [name, rates, initialRates]
  );

  const { isValid, isFixedValid, isMultiplierValid } = useMemo(
    () => isRateRulesValid(name, rates, ruleErrors),
    [name, rates, ruleErrors]
  );

  const handleInputChange = async (
    index: number,
    field: keyof RateRule,
    value: string,
    type: RateType
  ) => {
    handleUpdateRate(index, field, value, type);
    setRuleErrors(prevErrors => ({
      ...prevErrors,
      [`${type}-${field}-${index}`]: validateFields(field, value)
    }));
  };

  const handleSave = async (isSaveAndAdd: boolean) => {
    if (isNameTaken(name, initialRates, allSavedRates)) {
      setNameError("Name already taken. Choose another.");
      showToast("Correct all errors before saving.", { severity: "error" });
      return;
    }

    const config = formatRates(rates);
    try {
      await onSave({ name, config });
      showToast("Record saved.", { severity: "success" });
      if (isSaveAndAdd) {
        setName("");
        resetRates();
      } else onClose();
    } catch (e) {
      showToast(`Error: ${(e as Error).message}`, { severity: "error" });
    }
  };

  const handleCloseModal = () => {
    if (
      (action === "Create" && isRateRulesFilled(name, rates)) ||
      (action === "Edit" && isModified)
    ) {
      setIsCancelModalOpen(true);
    } else {
      onClose();
    }
  };

  return (
    <RotaNewModal
      onClose={handleCloseModal}
      size="medium"
      header={
        <ModalHeader
          title={`${action} Pay Rates`}
          endAction={
            <RotaButtonIcon onClick={handleCloseModal}>
              <Cancel />
            </RotaButtonIcon>
          }
        />
      }
    >
      <InputContainer>
        <RotaInputNew
          label="Name"
          placeholder="Enter a name"
          helperText="Please provide a name"
          value={name}
          onChange={({ target: { value } }) => {
            setName(value);
            setNameError("");
          }}
          isError={!!nameError}
          errorMessage={nameError}
        />
        <RateRulesForm
          type="multiplier"
          rates={rates.multiplier}
          resetRates={resetRates}
          errors={ruleErrors}
          isAddMore={isMultiplierValid}
          onInputChange={handleInputChange}
          onAddRate={handleAddRate}
          onDeleteRate={handleDeleteRate}
        />
        <RateRulesForm
          type="fixed"
          rates={rates.fixed}
          resetRates={resetRates}
          errors={ruleErrors}
          isAddMore={isFixedValid}
          onInputChange={handleInputChange}
          onAddRate={handleAddRate}
          onDeleteRate={handleDeleteRate}
        />
      </InputContainer>

      <SaveButtons
        action={action}
        isEnabled={isValid && isModified}
        onSave={handleSave}
      />

      {isCancelModalOpen && (
        <CancelModal
          onCancel={() => setIsCancelModalOpen(false)}
          onClose={onClose}
        />
      )}
    </RotaNewModal>
  );
};

export default CreateEditRatesModal;
