import React, { useState, useEffect } from "react";
import {
  RotaInputNew,
  DropdownSelectNew,
  iconNew as RotaIcon,
  RotaButtonNew
} from "@teamrota/rota-design";
import { Variant } from "@teamrota/rota-design/dist/components/button";
import { BooleanMap, RateRulesFormProps } from "../types";
import { rateNameOptions } from "../utils";
import { InputRow, StyledCheckbox } from "../styles";

const { Bin } = RotaIcon;

const RateRulesForm = ({
  type,
  rates,
  resetRates,
  errors,
  isAddMore,
  onInputChange,
  onAddRate,
  onDeleteRate
}: RateRulesFormProps) => {
  const [isShowForm, setIsShowForm] = useState<boolean>(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState<BooleanMap>({});

  useEffect(() => {
    setIsShowForm(rates.length > 0);
  }, [rates]);

  const onDropdownToggle = (key: string) => {
    setIsDropdownOpen(prevState => ({
      ...prevState,
      [key]: !prevState[key]
    }));
  };

  const isFixed = type === "fixed";

  const checkboxLabel = isFixed
    ? "Add Fixed Pay Rates"
    : "Add Multiplier Rates";
  const labelText = isFixed ? "Pay rate" : "Multiplier rate";
  const placeholderText = isFixed
    ? "Select a fixed pay rate"
    : "Select a multiplier rate";
  const helperText = isFixed
    ? "Please enter the numerical rate to 2 decimal places"
    : "Please enter the numerical rate to 3 decimal places";
  const fieldKey = isFixed ? "amount" : "factor";

  return (
    <div>
      <StyledCheckbox
        label={checkboxLabel}
        isChecked={isShowForm}
        onClick={() => {
          const checked = !isShowForm;
          setIsShowForm(checked);
          if (!checked) resetRates(type);
          else onAddRate(type);
        }}
      />
      {isShowForm && (
        <>
          {rates.map((rule, index) => (
            <InputRow key={index}>
              <DropdownSelectNew
                labelText={labelText}
                placeholderText={placeholderText}
                options={rateNameOptions}
                selectedValue={rule.name}
                isOpen={isDropdownOpen[`${type}-name-${index}`]}
                setIsOpen={() => onDropdownToggle(`${type}-name-${index}`)}
                onSelectOption={value =>
                  onInputChange(index, "name", value, type)
                }
              />
              <RotaInputNew
                label="Rate"
                placeholder="Enter a number"
                helperText={helperText}
                iconLeft={isFixed ? "£" : "x"}
                value={rule[fieldKey] as string}
                onChange={e =>
                  onInputChange(index, fieldKey, e.target.value, type)
                }
                isError={!!errors[`${type}-${fieldKey}-${index}`]}
                errorMessage={errors[`${type}-${fieldKey}-${index}`]}
              />
              <Bin onClick={() => onDeleteRate(index, type)} />
            </InputRow>
          ))}
          <RotaButtonNew
            variant={Variant.outlined}
            isDisabled={!isAddMore}
            onClick={() => onAddRate(type)}
          >
            Add another
          </RotaButtonNew>
        </>
      )}
    </div>
  );
};

export default RateRulesForm;
