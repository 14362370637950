import React, { useMemo, useState } from "react";
import {
  ModalHeader,
  RotaButtonIcon,
  RotaNewModal,
  iconNew,
  useToast
} from "@teamrota/rota-design";
import Stepper from "../Stepper";
import Client from "./components/Client";
import PayRate from "./components/PayRate";
import Calculation from "./components/Calculation";
import PayScale from "./components/PayScale";
import Rules from "./components/Rules";
import Summary from "./components/Summary";
import { Props, FormState } from "./types";
import { isRateCardValid, isRateCardFilled, getRateCards } from "./utils";
import { useCreateRateCards } from "../../graphql/use-create-payroll-rate-card";
import CancelModal from "../CancelModal";

const { Cancel } = iconNew;

const initialState: FormState = {
  selectedPartnerId: "",
  selectedRoles: [],
  selectedVenues: [],
  selectedRate: "",
  selectedCharge: "",
  selectedScale: "",
  selectedRule: "",
  selectedDate: new Date()
};

const CreateRateModal = ({ onClose }: Props) => {
  const [formState, setFormState] = useState<FormState>(initialState);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState<boolean>(false);

  const { showToast } = useToast();

  const updateForm = <K extends keyof FormState>(
    field: K,
    value: FormState[K]
  ) => {
    setFormState(prevState => ({ ...prevState, [field]: value }));
  };

  const isClientValid = useMemo(() => {
    return (
      !!formState.selectedPartnerId &&
      !!formState.selectedRoles.length &&
      !!formState.selectedVenues.length
    );
  }, [
    formState.selectedPartnerId,
    formState.selectedRoles,
    formState.selectedVenues
  ]);

  const isSaveEabled = useMemo(() => {
    return isRateCardValid(formState);
  }, [formState]);

  const {
    createPayrollRateCards,
    loading: creatingRateCards
  } = useCreateRateCards();

  const onSave = async () => {
    try {
      await createPayrollRateCards(getRateCards(formState));
      showToast("Rate cards created successfully.");
      onClose();
    } catch (error) {
      console.error("Error creating rate card:", error);
    }
  };

  const handleCloseModal = () => {
    if (isRateCardFilled(formState)) {
      setIsCancelModalOpen(true);
    } else onClose();
  };

  return (
    <RotaNewModal
      onClose={handleCloseModal}
      header={
        <ModalHeader
          title="Create a rate card"
          endAction={
            <RotaButtonIcon onClick={handleCloseModal}>
              <Cancel />
            </RotaButtonIcon>
          }
        />
      }
    >
      <Stepper
        steps={[
          { label: "Client", isValid: isClientValid },
          { label: "Pay rate", isValid: !!formState.selectedRate },
          { label: "Calculation", isValid: !!formState.selectedCharge },
          { label: "Pay scale", isValid: !!formState.selectedScale },
          { label: "Rules", isValid: !!formState.selectedRule },
          { label: "Summary", isValid: isSaveEabled }
        ]}
        onSave={onSave}
        isSaving={creatingRateCards}
      >
        {(activeStepIndex: number) => {
          switch (activeStepIndex) {
            case 0:
              return <Client formState={formState} updateForm={updateForm} />;
            case 1:
              return <PayRate formState={formState} updateForm={updateForm} />;
            case 2:
              return (
                <Calculation formState={formState} updateForm={updateForm} />
              );
            case 3:
              return <PayScale formState={formState} updateForm={updateForm} />;
            case 4:
              return <Rules formState={formState} updateForm={updateForm} />;
            case 5:
              return <Summary formState={formState} updateForm={updateForm} />;
            default:
              return null;
          }
        }}
      </Stepper>

      {isCancelModalOpen && (
        <CancelModal
          onCancel={() => setIsCancelModalOpen(false)}
          onClose={onClose}
        />
      )}
    </RotaNewModal>
  );
};

export default CreateRateModal;
