import React, { useState, useEffect } from "react";
import {
  RotaInputNew,
  DropdownSelectNew,
  DropdownMultiSelectNew,
  iconNew as RotaIcon,
  RotaButtonNew
} from "@teamrota/rota-design";
import { Variant } from "@teamrota/rota-design/dist/components/button";
import { BooleanMap, ChargeRulesFormProps } from "../types";
import { InputRow, StyledCheckbox } from "../styles";
import { chargeNameOptions } from "../utils";

const { Bin } = RotaIcon;

const FixedChargeRulesForm = ({
  charges,
  resetCharges,
  errors,
  isAddMore,
  onInputChange,
  onAddCharge,
  onDeleteCharge
}: ChargeRulesFormProps) => {
  const [isShowForm, setIsShowForm] = useState<boolean>(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState<BooleanMap>({});

  const type = "fixed";

  useEffect(() => {
    setIsShowForm(charges.length > 0);
  }, [charges]);

  const onDropdownToggle = (key: string) => {
    setIsDropdownOpen(prevState => ({
      ...prevState,
      [key]: !prevState[key]
    }));
  };

  return (
    <div>
      <StyledCheckbox
        label="Add fixed charge"
        isChecked={isShowForm}
        onClick={() => {
          const checked = !isShowForm;
          setIsShowForm(checked);
          if (!checked) resetCharges(type);
          else onAddCharge(type);
        }}
      />
      {isShowForm && (
        <>
          {charges.map((rule, index) => (
            <InputRow key={index} isIcon={true}>
              <DropdownSelectNew
                labelText="Charge name"
                placeholderText="Select a charge name"
                options={chargeNameOptions.filter((_, i) => i !== 0)}
                selectedValue={rule.name}
                isOpen={isDropdownOpen[`${type}-name-${index}`]}
                setIsOpen={() => onDropdownToggle(`${type}-name-${index}`)}
                onSelectOption={value =>
                  onInputChange(index, "name", value, type)
                }
              />
              <RotaInputNew
                label="Value"
                placeholder="Enter a number"
                helperText="Please enter the numerical charge to 2 decimal places"
                value={rule.factor as string}
                onChange={e =>
                  onInputChange(index, "factor", e.target.value, type)
                }
                isError={!!errors[`${type}-factor-${index}`]}
                errorMessage={errors[`${type}-factor-${index}`]}
              />
              <DropdownMultiSelectNew
                labelText="Charge from"
                placeholderText="Select where to charge from"
                options={chargeNameOptions}
                selectedValue={rule.of}
                isOpen={isDropdownOpen[`${type}-of-${index}`]}
                setIsOpen={() => onDropdownToggle(`${type}-of-${index}`)}
                onSelectOption={value =>
                  onInputChange(index, "of", value, type)
                }
              />
              <Bin onClick={() => onDeleteCharge(index, type)} />
            </InputRow>
          ))}
          <RotaButtonNew
            variant={Variant.outlined}
            isDisabled={!isAddMore}
            onClick={() => onAddCharge(type)}
          >
            Add another
          </RotaButtonNew>
        </>
      )}
    </div>
  );
};

export default FixedChargeRulesForm;
