import styled from "styled-components";
import { RotaCheckbox } from "@teamrota/rota-design";

export const InputContainer = styled.div`
  height: 550px;
  overflow-y: auto;
  > div {
    margin: 12px 0 30px;
  }
`;

export const InputRow = styled.div<{ size?: number; isIcon?: boolean }>`
  display: grid;
  grid-template-columns: repeat(${({ size = 3 }) => size}, 1fr) auto;
  align-items: center;
  column-gap: 16px;
  margin: 12px 0 ${({ size = 3 }) => (size < 3 ? "30px" : "42px")} !important;
`;

export const StyledCheckbox = styled(RotaCheckbox)`
  display: block !important;
  > label {
    justify-content: left !important;
  }
`;
