import React, { useState } from "react";
import { DropdownSelectNew } from "@teamrota/rota-design";
import { usePayrollCalculations } from "~/src/containers/payroll-calculations/graphql/use-payroll-calculations";
import { RateCardProps } from "../types";
import { getDropdownValuesFromData } from "../utils";

const Calculation = ({ formState, updateForm }: RateCardProps) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

  const { payrollCalculations } = usePayrollCalculations("");

  return (
    <DropdownSelectNew
      labelText="Charge calculation"
      placeholderText="Select a charge"
      options={getDropdownValuesFromData(payrollCalculations)}
      selectedValue={formState.selectedCharge}
      isOpen={isDropdownOpen}
      setIsOpen={() => setIsDropdownOpen(!isDropdownOpen)}
      onSelectOption={(value: string) => {
        updateForm("selectedCharge", value);
      }}
    />
  );
};

export default Calculation;
