import styled from "styled-components";

export const InputContainer = styled.div`
  height: 470px;
  overflow-y: auto;
  > div {
    margin: 12px 0 42px;
  }
`;

export const InputRow = styled.div`
  display: flex;
  column-gap: 32px;
`;

export const InputGroup = styled.div`
  width: 50%;
`;

export const ChipsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 32px;
`;
