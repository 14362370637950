import React, { useState } from "react";
import { DropdownSelectNew } from "@teamrota/rota-design";
import { usePayrollRules } from "~/src/containers/payroll-rules/graphql/use-payroll-rules";
import { RateCardProps } from "../types";
import { getDropdownValuesFromData } from "../utils";

const Rules = ({ formState, updateForm }: RateCardProps) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

  const { payrollRules } = usePayrollRules("");

  return (
    <DropdownSelectNew
      labelText="Rules"
      placeholderText="Select a rule"
      options={getDropdownValuesFromData(payrollRules)}
      selectedValue={formState.selectedRule}
      isOpen={isDropdownOpen}
      setIsOpen={() => setIsDropdownOpen(!isDropdownOpen)}
      onSelectOption={(value: string) => {
        updateForm("selectedRule", value);
      }}
    />
  );
};

export default Rules;
