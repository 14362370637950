import React, { useState } from "react";
import {
  DropdownMultiSelectNew,
  DropdownSelectNew,
  RotaChip
} from "@teamrota/rota-design";
import usePartnerDetails from "../hooks/use-partner-details";
import { BooleanMap, RateCardProps } from "../types";
import { getDropdownValuesFromData } from "../utils";
import { ChipsContainer, InputGroup, InputRow } from "../styles";
import { usePartners } from "~/src/graphql/queries/use-partners";

const Client = ({ formState, updateForm }: RateCardProps) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState<BooleanMap>({});

  const { selectedPartnerId, selectedRoles, selectedVenues } = formState;

  const { partners } = usePartners();
  const { roles, venues } = usePartnerDetails({
    partnerId: selectedPartnerId
  });

  const onDropdownToggle = (key: string) => {
    setIsDropdownOpen(prevState => ({
      ...prevState,
      [key]: !prevState[key]
    }));
  };

  const partnerOptions = [
    { value: "framework", label: "Framework" },
    ...getDropdownValuesFromData(partners)
  ]?.sort((a, b) => a.label.localeCompare(b.label));

  const venueOptions = [
    { value: "all", label: "All Venues" },
    ...getDropdownValuesFromData(venues)
  ];

  const onPartnerSelect = (value: string) => {
    updateForm("selectedPartnerId", value);
    updateForm("selectedVenues", []);
    updateForm("selectedRoles", []);
  };

  const onAddRole = (roleIds: string[]) => {
    const newSelectedRoles = roles.filter(role =>
      roleIds.some(id => id === role.id)
    );
    updateForm("selectedRoles", newSelectedRoles);
  };

  const onRemoveRole = (roleId: string) => {
    const newSelectedRoles = selectedRoles.filter(role => role.id !== roleId);
    updateForm("selectedRoles", newSelectedRoles);
  };

  const onAddVenue = (venueIds: string[]) => {
    const isAllVenues = venueIds.includes("all");
    const isLastVenueAll = venueIds[venueIds.length - 1] === "all";

    if (isAllVenues && isLastVenueAll) {
      updateForm("selectedVenues", [{ id: "all", name: "All Venues" }]);
      return;
    }

    if (isAllVenues && !isLastVenueAll) {
      const specificVenueIds = venueIds.filter(id => id !== "all");
      updateForm(
        "selectedVenues",
        venues.filter(({ id }) => specificVenueIds.includes(id))
      );
      return;
    }

    updateForm(
      "selectedVenues",
      venues.filter(({ id }) => venueIds.includes(id))
    );
  };

  const onRemoveVenue = (venueId: string) => {
    const newSelectedVenues = selectedVenues.filter(
      venue => venue.id !== venueId
    );
    updateForm("selectedVenues", newSelectedVenues);
  };

  return (
    <>
      <DropdownSelectNew
        labelText="Select the partner"
        placeholderText="Partner"
        options={partnerOptions}
        selectedValue={selectedPartnerId}
        isOpen={isDropdownOpen["partner"]}
        setIsOpen={() => onDropdownToggle("partner")}
        onSelectOption={onPartnerSelect}
      />
      <InputRow>
        <InputGroup>
          <DropdownMultiSelectNew
            labelText="Select the role"
            placeholderText="Role"
            options={getDropdownValuesFromData(roles)}
            selectedValue={selectedRoles.map(({ id }) => id)}
            isOpen={isDropdownOpen["role"]}
            setIsOpen={() => onDropdownToggle("role")}
            onSelectOption={onAddRole}
            isDisabled={!selectedPartnerId}
          />
          <ChipsContainer>
            {selectedRoles.map(role => (
              <RotaChip
                key={role.id}
                chipText={role.name || ""}
                onClose={() => onRemoveRole(role.id)}
              />
            ))}
          </ChipsContainer>
        </InputGroup>
        <InputGroup>
          <DropdownMultiSelectNew
            labelText="Select the venue"
            placeholderText="Venue"
            options={venueOptions}
            selectedValue={selectedVenues.map(({ id }) => id)}
            isOpen={isDropdownOpen["venue"]}
            setIsOpen={() => onDropdownToggle("venue")}
            onSelectOption={onAddVenue}
            isDisabled={!selectedPartnerId}
          />
          <ChipsContainer>
            {selectedVenues.map(venue => (
              <RotaChip
                key={venue.id}
                chipText={venue.name || ""}
                onClose={() => onRemoveVenue(venue.id)}
              />
            ))}
          </ChipsContainer>
        </InputGroup>
      </InputRow>
    </>
  );
};

export default Client;
