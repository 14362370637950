import styled from "styled-components";

export const InputContainer = styled.div`
  height: 470px;
  overflow-y: auto;
  > div {
    margin: 12px 0 30px;
  }
`;

export const StepperContainer = styled.div`
  margin: 0 16px 50px !important;
  p {
    margin: 16px 0 !important;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 16px;
`;
