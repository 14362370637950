import React from "react";
import { RotaInputNew } from "@teamrota/rota-design";
import { ScalePeriodFormProps } from "../types";
import { InputRow } from "../styles";

const ScalePeriodForm = ({
  period,
  errors,
  onInputChange
}: ScalePeriodFormProps) => {
  return (
    <InputRow size={2}>
      <RotaInputNew
        label="Wks in Yr"
        placeholder="Enter the weeks per year"
        value={period.weeks}
        onChange={e => onInputChange("weeks", e.target.value)}
        isError={!!errors.weeks}
        errorMessage={errors.weeks}
      />
      <RotaInputNew
        label="Hrs per Wk"
        placeholder="Enter the hours per week"
        value={period.hours}
        onChange={e => onInputChange("hours", e.target.value)}
        isError={!!errors.hours}
        errorMessage={errors.hours}
      />
    </InputRow>
  );
};

export default ScalePeriodForm;
