import React, { useMemo, useState } from "react";
import {
  ModalHeader,
  RotaNewModal,
  RotaButtonIcon,
  RotaInputNew,
  iconNew as RotaIcon,
  useToast
} from "@teamrota/rota-design";
import FixedChargeRulesForm from "./components/FixedChargeRulesForm";
import VariableChargeRulesForm from "./components/VariableChargeRulesForm";
import CancelModal from "../CancelModal";
import SaveButtons from "../SaveButtons";
import useCalculations from "./hooks/use-calculations";
import { Props, ChargeRule, StringMap, ChargeType } from "./types";
import {
  formatCharges,
  isNameTaken,
  isChargeRulesValid,
  isChargeRulesModified,
  validateFields,
  isChargeRulesFilled
} from "./utils";
import { InputContainer } from "./styles";

const { Cancel } = RotaIcon;

const CreateEditCalculationsModal: React.FC<React.PropsWithChildren<Props>> = ({
  action,
  allSavedCharges,
  initialCharges,
  onClose,
  onSave
}) => {
  const {
    name,
    setName,
    charges,
    resetCharges,
    handleAddCharge,
    handleUpdateCharge,
    handleDeleteCharge
  } = useCalculations(initialCharges);

  const [nameError, setNameError] = useState<string>("");
  const [ruleErrors, setRuleErrors] = useState<StringMap>({});
  const [isCancelModalOpen, setIsCancelModalOpen] = useState<boolean>(false);

  const { showToast } = useToast();

  const isModified = useMemo(
    () => isChargeRulesModified(name, charges, initialCharges),
    [name, charges, initialCharges]
  );

  const { isValid, isFixedValid, isVariableValid } = useMemo(
    () => isChargeRulesValid(name, charges, ruleErrors),
    [name, charges, ruleErrors]
  );

  const handleInputChange = async (
    index: number,
    field: keyof ChargeRule,
    value: string | string[],
    type: ChargeType
  ) => {
    handleUpdateCharge(index, field, value, type);

    const updateErrors = (key: string, value: string) => {
      setRuleErrors(prevErrors => ({
        ...prevErrors,
        [key]: validateFields(field, value)
      }));
    };

    if (field === "factor" && Array.isArray(value)) {
      value.forEach((factor, factorIndex) => {
        updateErrors(`${type}-factor-${index}-${factorIndex}`, factor);
      });
    } else updateErrors(`${type}-${field}-${index}`, value as string);
  };

  const handleSave = async (isSaveAndAdd: boolean) => {
    if (isNameTaken(name, initialCharges, allSavedCharges)) {
      setNameError("Name already taken. Choose another.");
      showToast("Correct all errors before saving.", { severity: "error" });
      return;
    }

    const config = formatCharges(charges);
    try {
      await onSave({ name, config });
      showToast("Record saved.", { severity: "success" });
      if (isSaveAndAdd) {
        setName("");
        resetCharges();
      } else onClose();
    } catch (e) {
      showToast(`Error: ${(e as Error).message}`, { severity: "error" });
    }
  };

  const handleCloseModal = () => {
    if (
      (action === "Create" && isChargeRulesFilled(name, charges)) ||
      (action === "Edit" && isModified)
    ) {
      setIsCancelModalOpen(true);
    } else {
      onClose();
    }
  };

  return (
    <RotaNewModal
      onClose={handleCloseModal}
      size="medium"
      header={
        <ModalHeader
          title={`${action} Charge Calculations`}
          endAction={
            <RotaButtonIcon onClick={handleCloseModal}>
              <Cancel />
            </RotaButtonIcon>
          }
        />
      }
    >
      <InputContainer>
        <RotaInputNew
          label="Name"
          placeholder="Enter a name"
          helperText="Please provide a name"
          value={name}
          onChange={({ target: { value } }) => {
            setName(value);
            setNameError("");
          }}
          isError={!!nameError}
          errorMessage={nameError}
        />
        <FixedChargeRulesForm
          charges={charges.fixed}
          resetCharges={resetCharges}
          errors={ruleErrors}
          isAddMore={isFixedValid}
          onInputChange={handleInputChange}
          onAddCharge={handleAddCharge}
          onDeleteCharge={handleDeleteCharge}
        />
        <VariableChargeRulesForm
          charges={charges.variable}
          resetCharges={resetCharges}
          errors={ruleErrors}
          isAddMore={isVariableValid}
          onInputChange={handleInputChange}
          onAddCharge={handleAddCharge}
          onDeleteCharge={handleDeleteCharge}
        />
      </InputContainer>

      <SaveButtons
        action={action}
        isEnabled={isValid && isModified}
        onSave={handleSave}
      />

      {isCancelModalOpen && (
        <CancelModal
          onCancel={() => setIsCancelModalOpen(false)}
          onClose={onClose}
        />
      )}
    </RotaNewModal>
  );
};

export default CreateEditCalculationsModal;
