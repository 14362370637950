import React, { useState } from "react";
import { DropdownSelectNew } from "@teamrota/rota-design";
import { usePayrollRates } from "~/src/containers/payroll-rate/graphql/use-payroll-rates";
import { RateCardProps } from "../types";
import { getDropdownValuesFromData } from "../utils";

const PayRate = ({ formState, updateForm }: RateCardProps) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

  const { payrollRates } = usePayrollRates("");

  return (
    <DropdownSelectNew
      labelText="Pay rate"
      placeholderText="Select a rate"
      options={getDropdownValuesFromData(payrollRates)}
      selectedValue={formState.selectedRate}
      isOpen={isDropdownOpen}
      setIsOpen={() => setIsDropdownOpen(!isDropdownOpen)}
      onSelectOption={(value: string) => {
        updateForm("selectedRate", value);
      }}
    />
  );
};

export default PayRate;
