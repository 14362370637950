import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";

import { Role } from "@teamrota/authlib";

import {
  RotaButtonNew,
  RotaSearchInput,
  RotaButtonIcon,
  iconNew
} from "@teamrota/rota-design";

import useAuth from "~/src/auth/hooks/use-auth";
import HasRole from "~/src/containers/has-role";

import {
  Wrapper,
  HeaderWrapper,
  Box,
  Heading,
  Title,
  Actions,
  Subheader,
  SubheaderWrapper
} from "~/src/components/payroll/style";

import Table from "./Table";

import { useCreatePayrollScale } from "./graphql/use-create-payroll-scale";
import { useUpdatePayrollScale } from "./graphql/use-update-payroll-scale";
import { usePayrollScales } from "./graphql/use-payroll-scales";

import CreateEditScaleModal from "../payroll/components/CreateEditScalesModal";

const { Filter, ChevronLeft } = iconNew;

const PayScale = () => {
  const history = useHistory();
  const params = useParams() as any;
  const auth = useAuth();

  const canEdit = auth.hasRole(Role.PAYROLL_EDIT);

  const { createPayrollScale } = useCreatePayrollScale();
  const { updatePayrollScale } = useUpdatePayrollScale();

  const { payrollScales } = usePayrollScales("");

  const [isShowCreateModal, setIsShowCreateModal] = useState(false);
  const [selectedOpenId, setSelectedOpenId] = useState<string>("");

  useEffect(() => {
    if (params.id !== undefined) {
      if (payrollScales.find((p: any) => p.id === params.id)) {
        setSelectedOpenId(params.id);
      }
    }
  }, [params, payrollScales]);

  const handleOpenScale = (id: string) => setSelectedOpenId(id);

  const handleClose = () => {
    setSelectedOpenId("");
    history.push("/payroll/pay-scales");
  };

  return (
    <Wrapper>
      <HeaderWrapper>
        <RotaSearchInput onChange={() => {}} />
        <HasRole role={Role.PAYROLL_EDIT}>
          <RotaButtonNew onClick={() => setIsShowCreateModal(true)}>
            Create
          </RotaButtonNew>
        </HasRole>
      </HeaderWrapper>
      <SubheaderWrapper>
        <RotaButtonIcon onClick={() => history.push("/payroll")}>
          <ChevronLeft />
        </RotaButtonIcon>
        <Subheader>Pay scale</Subheader>
      </SubheaderWrapper>
      <Box>
        <Heading>
          <Title>{`All ${payrollScales?.length} records`}</Title>
          <Actions>
            <RotaButtonIcon onClick={() => {}}>
              <Filter />
            </RotaButtonIcon>
          </Actions>
        </Heading>

        <Table
          payrollScales={payrollScales}
          handleOpenScale={handleOpenScale}
          canEdit={canEdit}
        />
      </Box>

      {isShowCreateModal && (
        <CreateEditScaleModal
          action="Create"
          allSavedScales={payrollScales}
          onClose={() => setIsShowCreateModal(false)}
          onSave={async ({ name, config }) => {
            await createPayrollScale({ name, config });
          }}
        />
      )}

      {selectedOpenId && (
        <CreateEditScaleModal
          action="Edit"
          allSavedScales={payrollScales}
          initialScales={payrollScales.find(
            ({ id }: { id: string }) => id === selectedOpenId
          )}
          onClose={handleClose}
          onSave={async ({ name, config }) => {
            await updatePayrollScale({ id: selectedOpenId, name, config });
          }}
        />
      )}
    </Wrapper>
  );
};

export default PayScale;
