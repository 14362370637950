import { useState, useEffect } from "react";
import { ScaleRule, Scale, ScalePeriod } from "../types";
import { convertScaleConfigToRules, getScalePeriod } from "../utils";

const useScales = (initialScales?: Scale) => {
  const defaultPeriod = { weeks: "", hours: "" };
  const defaultScales = [{ amount: "" }];

  const [name, setName] = useState<string>("");
  const [period, setPeriod] = useState<ScalePeriod>(defaultPeriod);
  const [scales, setScales] = useState<ScaleRule[]>([]);

  useEffect(() => {
    if (initialScales?.config) {
      setName(initialScales.name);
      setPeriod(getScalePeriod());
      setScales(convertScaleConfigToRules(initialScales.config));
    } else resetScales();
  }, []);

  const resetScales = () => {
    setPeriod(defaultPeriod);
    setScales(defaultScales);
  };

  const handleAddScale = () => {
    setScales([...scales, ...defaultScales]);
  };

  const handleUpdateScale = (
    index: number,
    field: keyof ScaleRule,
    value: string
  ) => {
    const updatedScales = [...scales];
    updatedScales[index][field] = value;

    setScales(updatedScales);
  };

  const handleDeleteScale = (index: number) => {
    const updatedScales = scales.filter((_, i) => i !== index);
    setScales(updatedScales);
  };

  return {
    name,
    setName,
    period,
    setPeriod,
    scales,
    resetScales,
    handleAddScale,
    handleUpdateScale,
    handleDeleteScale
  };
};

export default useScales;
