import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";

import { Role } from "@teamrota/authlib";

import {
  RotaButtonNew,
  RotaSearchInput,
  RotaButtonIcon,
  iconNew
} from "@teamrota/rota-design";

import useAuth from "~/src/auth/hooks/use-auth";
import HasRole from "~/src/containers/has-role";

import {
  Wrapper,
  HeaderWrapper,
  Box,
  Heading,
  Title,
  Actions,
  Subheader,
  SubheaderWrapper
} from "~/src/components/payroll/style";

import Table from "./Table";

import { useCreatePayrollCalculation } from "./graphql/use-create-payroll-calculation";
import { useUpdatePayrollCalculation } from "./graphql/use-update-payroll-calculation";
import { usePayrollCalculations } from "./graphql/use-payroll-calculations";

import CreateEditCalculationsModal from "../payroll/components/CreateEditCalculationsModal";

const { Filter, ChevronLeft } = iconNew;

const Calculations = () => {
  const history = useHistory();
  const params = useParams() as any;
  const auth = useAuth();

  const canEdit = auth.hasRole(Role.PAYROLL_EDIT);

  const { createPayrollCalculation } = useCreatePayrollCalculation();
  const { updatePayrollCalculation } = useUpdatePayrollCalculation();

  const { payrollCalculations } = usePayrollCalculations("");

  const [isShowCreateModal, setIsShowCreateModal] = useState(false);
  const [selectedOpenId, setSelectedOpenId] = useState<string>("");

  useEffect(() => {
    if (params.id !== undefined) {
      if (payrollCalculations.find((p: any) => p.id === params.id)) {
        setSelectedOpenId(params.id);
      }
    }
  }, [params, payrollCalculations]);

  const handleOpenCalculation = (id: string) => setSelectedOpenId(id);

  const handleClose = () => {
    setSelectedOpenId("");
    history.push("/payroll/calculations");
  };

  return (
    <Wrapper>
      <HeaderWrapper>
        <RotaSearchInput onChange={() => {}} />
        <HasRole role={Role.PAYROLL_EDIT}>
          <RotaButtonNew onClick={() => setIsShowCreateModal(true)}>
            Create
          </RotaButtonNew>
        </HasRole>
      </HeaderWrapper>
      <SubheaderWrapper>
        <RotaButtonIcon onClick={() => history.push("/payroll")}>
          <ChevronLeft />
        </RotaButtonIcon>
        <Subheader>Calculations</Subheader>
      </SubheaderWrapper>
      <Box>
        <Heading>
          <Title>{`All ${payrollCalculations?.length} records`}</Title>
          <Actions>
            <RotaButtonIcon onClick={() => {}}>
              <Filter />
            </RotaButtonIcon>
          </Actions>
        </Heading>

        <Table
          payrollCalculations={payrollCalculations}
          handleOpenCalculation={handleOpenCalculation}
          canEdit={canEdit}
        />
      </Box>

      {isShowCreateModal && (
        <CreateEditCalculationsModal
          action="Create"
          allSavedCharges={payrollCalculations}
          onClose={() => setIsShowCreateModal(false)}
          onSave={async ({ name, config }) => {
            await createPayrollCalculation({ name, config });
          }}
        />
      )}

      {selectedOpenId && (
        <CreateEditCalculationsModal
          action="Edit"
          allSavedCharges={payrollCalculations}
          initialCharges={payrollCalculations.find(
            ({ id }: { id: string }) => id === selectedOpenId
          )}
          onClose={handleClose}
          onSave={async ({ name, config }) => {
            await updatePayrollCalculation({
              id: selectedOpenId,
              name,
              config
            });
          }}
        />
      )}
    </Wrapper>
  );
};

export default Calculations;
