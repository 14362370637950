import styled from "styled-components";

export const InputContainer = styled.div`
  height: 550px;
  overflow-y: auto;
  > div {
    margin: 12px 0 42px;
  }
`;

export const InputRow = styled.div<{ size?: number; isIcon?: boolean }>`
  display: grid;
  grid-template-columns:
    repeat(${({ size = 3 }) => size}, 1fr)
    ${({ isIcon }) => (isIcon ? "auto" : "")};
  align-items: center;
  column-gap: 16px;
`;
