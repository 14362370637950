import { DropdownItem, FormState } from "./types";

export const getDropdownValuesFromData = (data: DropdownItem[]) => {
  return (
    data?.map((item: any) => ({
      value: item.id,
      label: item.accountName || item.name
    })) || []
  );
};

export const isRateCardValid = (formState: FormState) => {
  return Object.values(formState).every(value =>
    Array.isArray(value)
      ? value.length > 0 && value.every(Boolean)
      : Boolean(value)
  );
};

export const isRateCardFilled = (formState: FormState) => {
  return Object.entries(formState)
    .filter(([key]) => key !== "selectedDate")
    .some(([, value]) =>
      Array.isArray(value)
        ? value.length > 0 && value.some(Boolean)
        : Boolean(value)
    );
};

export const getRateCards = (formState: FormState) => {
  const venueIds = formState.selectedVenues.map(venue => venue.id);

  return {
    partnerAccountId: formState.selectedPartnerId,
    roleIds: formState.selectedRoles.map(role => role.id),
    venueIds: venueIds,
    allVenues: venueIds.includes("all"),
    payrollRateId: formState.selectedRate,
    payrollCalculationId: formState.selectedCharge,
    payrollScaleId: formState.selectedScale,
    payrollRuleId: formState.selectedRule,
    effectiveFrom: formState.selectedDate
  };
};
