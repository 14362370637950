import { capitalize, isEqual } from "lodash/fp";
import { Rate, RateRule, RateRulesType, StringMap } from "./types";

export const rateNameOptions = [
  { label: "Day Shift (Mon-Sat)", value: "DAYSHIFTMONSAT" },
  { label: "Day Shift (Sunday)", value: "DAYSHIFTSUNDAY" },
  { label: "Night Shift (Mon-Sat)", value: "NIGHTSHIFTMONSAT" },
  { label: "Night Shift (Sunday)", value: "NIGHTSHIFTSUNDAY" },
  { label: "Saturday Premium", value: "SATURDAYPREMIUM" },
  { label: "Sleepover Hours", value: "SLEEPOVERHOURS" },
  { label: "Twilight (Mon-Sat)", value: "TWILIGHTMONSAT" },
  { label: "Twilight (Sunday)", value: "TWILIGHTSUNDAY" },
  { label: "Bank Holiday", value: "BANKHOL" },
  { label: "Bank Holiday (Night)", value: "BANKHOLNIGHT" },
  { label: "Bank Holiday (Twilight)", value: "BANKHOLTW" },
  { label: "Christmas/New Year Day Shift", value: "XMAS/NYDAYSHIFT" },
  { label: "Christmas/New Year Night Shift", value: "XMAS/NYNIGHTSHIFT" },
  { label: "Christmas/New Year Twilight", value: "XMAS/NYTW" }
];

export const getRates = (rates: Rate["config"]) => ({
  fixed: rates
    .filter(({ amount }) => amount !== undefined)
    .map(({ name = "", amount }) => ({ name, amount: String(amount) })),

  multiplier: rates
    .filter(({ factor }) => factor !== undefined)
    .map(({ name = "", factor }) => ({ name, factor: String(factor) }))
});

export const formatRates = ({ fixed, multiplier }: RateRulesType) => {
  const rates = [...fixed, ...multiplier];

  return rates.map(({ amount, factor, ...rest }) => ({
    ...rest,
    ...(amount !== undefined && { amount: parseFloat(amount) }),
    ...(factor !== undefined && { factor: parseFloat(factor) })
  }));
};

export const normalizeRates = (rates: Rate["config"]) =>
  rates.map(({ amount, factor, ...rest }) => ({
    ...rest,
    ...(amount !== undefined && { amount: String(amount) }),
    ...(factor !== undefined && { factor: String(factor) })
  }));

export const isNameTaken = (
  name: string,
  initialRates: Rate | undefined,
  allSavedRates: Rate[]
) =>
  allSavedRates.some(
    (br: Rate) =>
      br.name.toLowerCase() === name.toLowerCase().trim() &&
      br.name.toLowerCase() !== initialRates?.name.toLowerCase()
  );

export const isValid = (rates: RateRule[]) => {
  return (
    !!rates.length && rates.every(rate => Object.values(rate).every(Boolean))
  );
};

export const isErrors = (errors: StringMap, value: string) => {
  return Object.entries(errors).some(
    ([key, val]) => key.includes(value) && Boolean(val)
  );
};

export const isRateRulesFilled = (
  name: string,
  { fixed, multiplier }: RateRulesType
) => {
  const rates = [...fixed, ...multiplier];

  return (
    name.trim() !== "" ||
    rates.some(rate => Object.values(rate).some(value => value !== ""))
  );
};

export const isRateRulesValid = (
  name: string,
  { fixed, multiplier }: RateRulesType,
  errors: StringMap
) => {
  const isNameValid = name.trim() !== "";
  const isFixedValid = isValid(fixed) && !isErrors(errors, "fixed");
  const isMultiplierValid =
    isValid(multiplier) && !isErrors(errors, "multiplier");

  const isRatesValid =
    fixed.length > 0 && multiplier.length > 0
      ? isFixedValid && isMultiplierValid
      : isFixedValid || isMultiplierValid;

  return {
    isValid: isNameValid && isRatesValid,
    isFixedValid,
    isMultiplierValid
  };
};

export const isRateRulesModified = (
  name: string,
  { fixed, multiplier }: RateRulesType,
  initialRates: Rate | undefined
) => {
  if (!initialRates) return true;

  const rates = [...fixed, ...multiplier];

  return (
    name !== initialRates?.name ||
    !isEqual(rates, normalizeRates(initialRates.config))
  );
};

export const validateFields = (field: keyof RateRule, value: string) => {
  let error = "";

  const isValidNumber = /^[+-]?(\d+(\.\d*)?|\.\d+)$/;
  const label = capitalize(field);

  switch (field) {
    case "factor":
    case "amount":
      if (value && !isValidNumber.test(value)) {
        error = "Enter a valid number.";
      } else if (parseFloat(value) <= 0) {
        error = `${label} must be a positive number.`;
      } else if (field === "amount" && value.split(".")[1]?.length > 2) {
        error = `${label} cannot exceed 2 decimal places.`;
      } else if (field === "factor" && value.split(".")[1]?.length > 3) {
        error = `${label} cannot exceed 3 decimal places.`;
      }
      break;
  }

  return error;
};
