import React from "react";
import {
  RotaInputNew,
  RotaButtonNew,
  iconNew as RotaIcon
} from "@teamrota/rota-design";
import { Variant } from "@teamrota/rota-design/dist/components/button";
import { ScaleRulesFormProps, ScaleRule } from "../types";
import { InputRow } from "../styles";

const { Bin } = RotaIcon;

const ScaleRulesForm = ({
  scales,
  period,
  errors,
  isAddMore,
  onInputChange,
  onAddScale,
  onDeleteScale
}: ScaleRulesFormProps) => {
  return (
    <>
      {scales.map((rule: ScaleRule, index: number) => {
        const { weeks, hours } = period;
        const computedRate =
          rule.amount && weeks && hours
            ? (Number(rule.amount) / (Number(weeks) * Number(hours))).toFixed(2)
            : "";

        return (
          <InputRow key={index} size={4} isIcon={true}>
            <RotaInputNew label="Level" value={`${index + 1}`} readOnly />
            <RotaInputNew
              label="Name"
              value={`Pay scale ${index + 1}`}
              readOnly
            />
            <RotaInputNew
              label="Salary"
              placeholder="Enter an amount for base pay at this level"
              helperText="Please enter the salary to 2 decimal places"
              iconLeft="£"
              value={rule.amount}
              onChange={e => onInputChange("amount", e.target.value, index)}
              isError={!!errors[`amount-${index}`]}
              errorMessage={errors[`amount-${index}`]}
            />
            <RotaInputNew
              label="Rate"
              iconLeft="£"
              value={computedRate}
              isDisabled={true}
              readOnly
            />
            {scales.length > 1 && <Bin onClick={() => onDeleteScale(index)} />}
          </InputRow>
        );
      })}

      <RotaButtonNew
        variant={Variant.outlined}
        isDisabled={!isAddMore}
        onClick={onAddScale}
      >
        Add another
      </RotaButtonNew>
    </>
  );
};

export default ScaleRulesForm;
