import { capitalize, isEqual } from "lodash/fp";
import { ScaleRule, Scale, ScalePeriod, ErrorsType, StringMap } from "./types";

export const getScalePeriod = () => {
  return {
    weeks: "",
    hours: ""
  };
};

export const convertScaleConfigToRules = (config: Scale["config"]) => {
  return config[0].amount.map(x => ({
    amount: String(x ?? "")
  }));
};

export const convertScaleRulesToConfig = (
  period: ScalePeriod,
  scales: ScaleRule[]
) => {
  return [
    {
      name: "BASE",
      amount: scales.map(rule => parseFloat(rule.amount))
      // weeklyHours: parseFloat(period.hours),
      // weeksInYear: parseFloat(period.weeks)
    }
  ];
};

export const isNameTaken = (
  name: string,
  initialScales: Scale | undefined,
  allSavedScales: Scale[]
) =>
  allSavedScales.some(
    (br: Scale) =>
      br.name.toLowerCase() === name.toLowerCase().trim() &&
      br.name.toLowerCase() !== initialScales?.name.toLowerCase()
  );

export const isValid = (values: Record<string, any>) =>
  Object.values(values).every(Boolean);

export const isErrors = (errors: StringMap) =>
  Object.values(errors).some(Boolean);

export const isScaleRulesFilled = (
  name: string,
  period: ScalePeriod,
  scales: ScaleRule[]
) => {
  return (
    name.trim() !== "" ||
    Object.values(period).some(value => value !== "") ||
    scales.some(rule => Object.values(rule).some(value => value !== ""))
  );
};

export const isScaleRulesValid = (
  name: string,
  period: ScalePeriod,
  scales: ScaleRule[],
  errors: ErrorsType
) => {
  const isNameValid = name.trim() !== "";
  const isPeriodValid = isValid(period) && !isErrors(errors.period);
  const isRulesValid =
    scales.every(rule => isValid(rule)) && !isErrors(errors.rules);

  return {
    isValid: isNameValid && isPeriodValid && isRulesValid,
    isScalesValid: isRulesValid
  };
};

export const isScaleRulesModified = (
  name: string,
  period: ScalePeriod,
  scales: ScaleRule[],
  initialScales: Scale | undefined
) => {
  if (!initialScales) return true;

  return (
    name !== initialScales?.name ||
    !isEqual(period, getScalePeriod()) ||
    !isEqual(scales, convertScaleConfigToRules(initialScales.config))
  );
};

export const validateFields = (
  field: keyof ScaleRule | keyof ScalePeriod,
  value: string
) => {
  let error = "";

  const isValidNumber = /^[+-]?(\d+(\.\d*)?|\.\d+)$/;
  const label = capitalize(field);

  switch (field) {
    case "weeks":
    case "hours":
    case "amount":
      if (value && !isValidNumber.test(value)) {
        error = "Enter a valid number.";
      } else if (parseFloat(value) <= 0) {
        error = `${label} must be a positive number.`;
      } else if (value.split(".")[1]?.length > 2) {
        error = `${label} cannot exceed 2 decimal places.`;
      }
      break;
  }

  return error;
};
