import { isEqual } from "lodash/fp";
import { Charge, ChargeRule, ChargeRulesType, StringMap } from "./types";

export const chargeNameOptions = [
  { label: "Pay", value: "PAY" },
  { label: "Holiday Pay", value: "HOLIDAY" },
  { label: "Sick Pay", value: "SICK" },
  { label: "PRSI", value: "PRSI" },
  { label: "Margin", value: "MARGIN" },
  { label: "Admin", value: "ADMIN" }
];

export const getChargeName = (name: string | undefined) => {
  return chargeNameOptions.find(({ value }) => value === name)?.label || "";
};

export const getCharges = (charges: Charge["config"]) => {
  return charges.reduce<ChargeRulesType>(
    (acc, { factor, of, ...rest }) => {
      const category = Array.isArray(factor) ? "variable" : "fixed";

      acc[category].push({
        ...rest,
        of: Array.isArray(of) ? of : [of],
        factor: Array.isArray(factor)
          ? factor.map(num => String(num))
          : String(factor)
      });

      return acc;
    },
    { fixed: [], variable: [] }
  );
};

export const formatCharges = ({ fixed, variable }: ChargeRulesType) => {
  const charges = [...fixed, ...variable];

  return charges.map(charge => ({
    ...charge,
    of: charge.of.length > 1 ? charge.of : charge.of[0],
    factor: Array.isArray(charge.factor)
      ? charge.factor.map(x => parseFloat(x))
      : parseFloat(charge.factor),
    settle: "NET"
  }));
};

export const normalizeCharges = (charges: Charge["config"]) => {
  return charges.map(charge => ({
    ...charge,
    of: Array.isArray(charge.of) ? charge.of : [charge.of],
    factor: Array.isArray(charge.factor)
      ? charge.factor.map(x => String(x))
      : String(charge.factor)
  }));
};

export const isNameTaken = (
  name: string,
  initialCharges: Charge | undefined,
  allSavedCharges: Charge[]
) =>
  allSavedCharges.some(
    (br: Charge) =>
      br.name.toLowerCase() === name.toLowerCase().trim() &&
      br.name.toLowerCase() !== initialCharges?.name.toLowerCase()
  );

export const isValid = (charges: ChargeRule[]) => {
  return (
    !!charges.length &&
    charges.every(charge =>
      Object.values(charge).every(value =>
        Array.isArray(value)
          ? value.length > 0 && value.every(Boolean)
          : Boolean(value)
      )
    )
  );
};

export const isErrors = (errors: StringMap, value: string) => {
  return Object.entries(errors).some(
    ([key, val]) => key.includes(value) && Boolean(val)
  );
};

export const isChargeRulesFilled = (
  name: string,
  { fixed, variable }: ChargeRulesType
) => {
  const charges = [...fixed, ...variable];

  return (
    name.trim() !== "" ||
    charges.some(charge =>
      Object.values(charge).some(value =>
        Array.isArray(value)
          ? value.length > 0 && value.some(Boolean)
          : value !== ""
      )
    )
  );
};

export const isChargeRulesValid = (
  name: string,
  { fixed, variable }: ChargeRulesType,
  errors: StringMap
) => {
  const isNameValid = name.trim() !== "";
  const isFixedValid = isValid(fixed) && !isErrors(errors, "fixed");
  const isVariableValid = isValid(variable) && !isErrors(errors, "variable");

  const isChargesValid =
    fixed.length > 0 && variable.length > 0
      ? isFixedValid && isVariableValid
      : isFixedValid || isVariableValid;

  return {
    isValid: isNameValid && isChargesValid,
    isFixedValid,
    isVariableValid
  };
};

export const isChargeRulesModified = (
  name: string,
  { fixed, variable }: ChargeRulesType,
  initialCharges: Charge | undefined
) => {
  if (!initialCharges) return true;

  const charges = [...fixed, ...variable];

  return (
    name !== initialCharges?.name ||
    !isEqual(charges, normalizeCharges(initialCharges.config))
  );
};

export const validateFields = (field: keyof ChargeRule, value: string) => {
  let error = "";

  const isValidNumber = /^[+-]?(\d+(\.\d*)?|\.\d+)$/;

  switch (field) {
    case "factor":
      if (value && !isValidNumber.test(value)) {
        error = "Enter a valid number.";
      } else if (parseFloat(value) <= 0) {
        error = `Value must be a positive number.`;
      } else if (field === "factor" && value.split(".")[1]?.length > 2) {
        error = "Value cannot exceed 2 decimal places.";
      }
      break;
  }

  return error;
};
