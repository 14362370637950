import React, { useState } from "react";
import { DropdownSelectNew } from "@teamrota/rota-design";
import { usePayrollScales } from "~/src/containers/payroll-scale/graphql/use-payroll-scales";
import { RateCardProps } from "../types";
import { getDropdownValuesFromData } from "../utils";

const PayScale = ({ formState, updateForm }: RateCardProps) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

  const { payrollScales } = usePayrollScales("");

  return (
    <DropdownSelectNew
      labelText="Pay Scale"
      placeholderText="Select a scale"
      options={getDropdownValuesFromData(payrollScales)}
      selectedValue={formState.selectedScale}
      isOpen={isDropdownOpen}
      setIsOpen={() => setIsDropdownOpen(!isDropdownOpen)}
      onSelectOption={(value: string) => {
        updateForm("selectedScale", value);
      }}
    />
  );
};

export default PayScale;
